var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Vinculación de proyectos a cobertura y su vinculación a instrumentos de planificación ")])],1)],1),_c('v-row',{staticClass:"pb-1"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{staticClass:"pb-0",attrs:{"outlined":"","color":"#1488c2","border":"left"}},[_c('p',[_vm._v(" Los campos marcados con un asterisco ("),_c('span',{staticClass:"red--text",attrs:{"color":"red"}},[_vm._v("*")]),_vm._v(") son obligatorios ")])])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Vinculación estratégica a instrumentos internacionales ")])],1)],1),_c('v-form',{ref:"formCobertura",on:{"submit":function($event){$event.preventDefault();return _vm.registrarCobertura.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.ods,"loading":_vm.ddOdsLoading,"dense":"","filled":"","label":"Objetivo de Desarrollo Sostenible","item-text":"nombreODS","item-value":"id","no-data-text":_vm.pnds != null
                  ? 'Seleccione Objetivo de Desarrollo Sostenible'
                  : 'No se han encontrado Objetivos de Desarrollo Sostenible',"menu-props":"offset-y","rules":[
                _vm.selectRequired('Objetivo de Desarrollo Sostenible primario')
              ],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerMetasOds(_vm.datosCobertura.odsId)}},model:{value:(_vm.datosCobertura.odsId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "odsId", $$v)},expression:"datosCobertura.odsId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasOds,"loading":_vm.ddMetasOdsLoading,"dense":"","filled":"","label":"Meta Objetivo de Desarrollo Sostenible","item-text":"meta","item-value":"id","no-data-text":_vm.metasOds != null
                  ? 'Seleccione una Meta Objetivo de Desarrollo Sostenible'
                  : 'No se han encontrado Meta Objetivo de Desarrollo Sostenible',"menu-props":"offset-y","rules":[_vm.selectRequired('meta ods')],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosCobertura.metasODSId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasODSId", $$v)},expression:"datosCobertura.metasODSId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Vinculación estratégica a instrumentos nacionales de planificación ")])],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.pnds,"loading":_vm.ddPndLoading,"dense":"","filled":"","label":"Prioridad Nacional de Desarrollo","item-text":"nombrePND","item-value":"id","no-data-text":_vm.pnds != null
                  ? 'Seleccione Prioridad Nacional de Desarrollo'
                  : 'No se han encontrado Prioridades Nacionales de Desarrollo',"menu-props":"offset-y","rules":[_vm.selectRequired('pnd')],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerMetasPnd(_vm.datosCobertura.pndId)}},model:{value:(_vm.datosCobertura.pndId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pndId", $$v)},expression:"datosCobertura.pndId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.metasPnd,"loading":_vm.ddMetasPndLoading,"dense":"","filled":"","label":"Meta Prioridad Nacional de Desarrollo","item-text":"meta","item-value":"id","no-data-text":_vm.metasPnd != null
                  ? 'Seleccione una Meta Prioridad Nacional de Desarrollo'
                  : 'No se han encontrado Metas Prioridad Nacional de Desarrollo',"menu-props":"offset-y","rules":[_vm.selectRequired('meta pnd')],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosCobertura.metaPNDId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metaPNDId", $$v)},expression:"datosCobertura.metaPNDId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.ejesKatun,"clearable":true,"loading":_vm.ddEjesKatunLoading,"dense":"","filled":"","label":"Eje K´atun","item-text":"nombreEje","item-value":"id","no-data-text":_vm.ejesKatun != null
                  ? 'Seleccione Eje K´atun'
                  : 'No se han encontrado Ejes K´atun',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerPrioridadesKatun(_vm.datosCobertura.ejesKatunId)}},model:{value:(_vm.datosCobertura.ejesKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "ejesKatunId", $$v)},expression:"datosCobertura.ejesKatunId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.prioridadesKatun,"clearable":true,"loading":_vm.ddPrioridadesKatunLoading,"dense":"","filled":"","label":"Prioridad K´atun","item-text":"prioridad","item-value":"id","no-data-text":_vm.prioridadesKatun != null
                  ? 'Seleccione una prioridad K´atun'
                  : 'No se han encontrado prioridades K´atun',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerMetasKatun(_vm.datosCobertura.prioridadesKatunId)}},model:{value:(_vm.datosCobertura.prioridadesKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "prioridadesKatunId", $$v)},expression:"datosCobertura.prioridadesKatunId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.metasKatun,"clearable":true,"loading":_vm.ddMetasKatunLoading,"dense":"","filled":"","label":"Meta K´atun","item-text":"meta","item-value":"id","no-data-text":_vm.metasKatun != null
                  ? 'Seleccione una meta K´atun'
                  : 'No se han encontrado metas K´atun',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosCobertura.metasKatunId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasKatunId", $$v)},expression:"datosCobertura.metasKatunId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.pggs,"loading":_vm.ddPggLoading,"dense":"","filled":"","label":"Política General de Gobierno","item-text":"nombrePGG","item-value":"id","no-data-text":_vm.pggs != null
                  ? 'Seleccione Política General de Gobierno'
                  : 'No se han encontrado Políticas Generales de Gobierno',"menu-props":"offset-y","rules":[_vm.selectRequired('Política General de Gobierno')],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerPilaresPgg(_vm.datosCobertura.pggId)}},model:{value:(_vm.datosCobertura.pggId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pggId", $$v)},expression:"datosCobertura.pggId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{attrs:{"clearable":true,"items":_vm.pilaresPgg,"loading":_vm.ddPilaresPggLoading,"dense":"","filled":"","label":"Pilar Política General de Gobierno","item-text":"pilar","item-value":"id","no-data-text":_vm.pilaresPgg != null
                  ? 'Seleccione un Pilar Política General de Gobierno'
                  : 'No se han encontrado Pilares Política General de Gobierno',"menu-props":"offset-y","rules":[
                /*selectRequired('Pilar Política General de Gobierno')*/
              ],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerMetasEstrategicas(_vm.datosCobertura.pilarPGGId)}},model:{value:(_vm.datosCobertura.pilarPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "pilarPGGId", $$v)},expression:"datosCobertura.pilarPGGId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{attrs:{"clearable":true,"items":_vm.metasEstrategicasPgg,"loading":_vm.ddMetasEstrategicasPggLoading,"dense":"","filled":"","label":"Meta estratégica Política General de Gobierno","item-text":"meta","item-value":"id","no-data-text":_vm.metasEstrategicasPgg != null
                  ? 'Seleccione una Meta estratégica Política General de Gobierno'
                  : 'No se han encontrado Metas estratégicas Política General de Gobierno',"menu-props":"offset-y","rules":[
                // selectRequired(
                // 'Meta estrategica Política General de Gobiernogg'
                //),
              ],"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.obtenerObjetivosEstrategicosPgg(
                  _vm.datosCobertura.metasEstrategicasPGGId
                )}},model:{value:(_vm.datosCobertura.metasEstrategicasPGGId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "metasEstrategicasPGGId", $$v)},expression:"datosCobertura.metasEstrategicasPGGId"}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{attrs:{"clearable":true,"items":_vm.politicasPublicas,"loading":_vm.politicaPublicaLoading,"dense":"","filled":"","label":"Política pública","item-text":"politica","item-value":"id","no-data-text":_vm.politicasPublicas != null
                  ? 'Seleccione un polítca pública'
                  : 'No se han encontrado polítcas públicas',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosCobertura.politicaPublicaId),callback:function ($$v) {_vm.$set(_vm.datosCobertura, "politicaPublicaId", $$v)},expression:"datosCobertura.politicaPublicaId"}})],1)],1),(!_vm.seccionesBloqueadas && !_vm.confirmacion)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.vinculacionRegistrada ? "Guardar información" : "Guardar información")+" ")])],1)],1):_vm._e()],1)],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }