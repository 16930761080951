<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <!--inicio:: condiciones de beneficiarios -->
      <v-row>
        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
          <v-subheader class="text-h5 black--text">
            Fuente cooperante y financiamiento del proyecto
          </v-subheader>
        </v-col>
      </v-row>

      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-form
            ref="formTipoBeneficiarios"
            v-on:submit.prevent="registrarDatosFinanciamiento"
            v-model="validForm"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0 mt-0">
                <v-select
                  v-model="datosItem.procedenciaFondos"
                  :items="procedenciaFondos"
                  :loading="ddProcedenciaFondosLoading"
                  dense
                  filled
                  class="required"
                  label="Procedencia de los fondos"
                  item-text="procedencia"
                  item-value="id"
                  :rules="[selectRequired('Procedencia de los fondos')]"
                  :no-data-text="
                    procedenciaFondos.length > 0
                      ? 'Seleccione una procedencia de los fondos'
                      : 'No se han encontrado procedencia de fondos'
                  "
                  @change="configurarProcedenciaFondos"
                  menu-props="offset-y"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
                class="pt-0 pb-0 mt-0"
                v-if="datosItem.procedenciaFondos == 2"
              >
                <v-select
                  v-model="datosItem.paisId"
                  :items="paises"
                  :loading="ddPaisesLoading"
                  dense
                  filled
                  class="required"
                  label="País"
                  :item-text="item => `${item.nombre}`"
                  item-value="id"
                  :rules="[
                    datosItem.procedenciaFondos === 2
                      ? selectRequired('pais')
                      : true
                  ]"
                  :no-data-text="
                    procedenciaFondos.length > 0
                      ? 'Seleccione un país'
                      : 'No se han encontrado países'
                  "
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                  menu-props="offset-y"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosItem.nombreCooperante"
                  label="Nombre del Cooperante"
                  :rules="[
                    required('Nombre del Cooperant'),
                    minLength('Nombre del Cooperant', 10),
                    maxLength('Nombre del Cooperant', 250)
                  ]"
                  maxlength="250"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0 mt-0">
                <v-select
                  v-model="datosItem.modalidadCooperacionAclId"
                  :items="modalidadesCooperacion"
                  :loading="ddModalidadesCooperacionLoading"
                  dense
                  filled
                  class="required"
                  label="Modalidad de cooperación"
                  :item-text="
                    item => item.tipoProyecto + ` - ` + item.modalidad
                  "
                  item-value="id"
                  :rules="[selectRequired('Modalidad de cooperación')]"
                  :no-data-text="
                    procedenciaFondos.length > 0
                      ? 'Seleccione un Modalidad de cooperación'
                      : 'No se han encontrado Modalidades de cooperación'
                  "
                  menu-props="offset-y"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0 mt-0">
                <v-select
                  v-model="datosItem.tipoCooperacionAclId"
                  :items="tiposCooperacion"
                  :loading="ddTiposCooperacionLoading"
                  dense
                  filled
                  class="required"
                  label="Tipo de cooperación "
                  item-text="tipoCooperacion"
                  item-value="id"
                  :rules="[selectRequired('Tipo de cooperación')]"
                  :no-data-text="
                    procedenciaFondos.length > 0
                      ? 'Seleccione un tipo de cooperación'
                      : 'No se han encontrado tipos de cooperación'
                  "
                  @change="configurarInstituciones()"
                  menu-props="offset-y"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="datosItem.procedenciaFondos === 2">
              <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text ">
                  Países secundarios
                </v-subheader>
              </v-col>

              <v-col cols="12" md="12" class="pt-0 mt-0">
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
            </v-row>

            <v-form
              ref="formTipoBeneficiarios"
              v-on:submit.prevent="registrarPaisSecundario"
              v-model="validFormPaisSecundario"
            >
              <v-row>
                <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0 mt-0">
                  <v-select
                    v-model="datosPaisSecundario.paisId"
                    :items="paises"
                    :loading="ddPaisesLoading"
                    dense
                    filled
                    class="required"
                    label="País"
                    :item-text="item => `${item.nombre}`"
                    item-value="id"
                    :rules="[
                      datosItem.procedenciaFondos === 2
                        ? selectRequired('pais')
                        : true
                    ]"
                    :no-data-text="
                      procedenciaFondos.length > 0
                        ? 'Seleccione un país'
                        : 'No se han encontrado países'
                    "
                    :disabled="
                      (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                    "
                    menu-props="offset-y"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0 mt-0">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    medium
                    :elevation="0"
                    :disabled="!validFormPaisSecundario"
                    :loading="btnPaisSecundarioLoading"
                  >
                    Registrar país secundario
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  class="elevation-0"
                  :headers="headersPaises"
                  :items="paisesSecundarios"
                  :loading="tablePaisesLoading"
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :header-props="{
                    sortByText: 'Ordenar por'
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}'
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.nombre }}</td>
                      <td>
                        <v-btn
                          class="ma-2 btn-bg-light red--text darken-1--text font-weight-medium text-capitalize"
                          small
                          depressed
                          color="red lighten-4"
                          @click="mostrarDialogEliminarPais(item)"
                          v-if="!confirmacion"
                        >
                          <v-icon left>mdi-close</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text ">
                  Datos Financieros
                </v-subheader>
              </v-col>

              <v-col cols="12" md="12" class="pt-0 mt-0">
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="monedaSeleccionada"
                  :items="monedas"
                  :loading="ddMonedasLoading"
                  dense
                  filled
                  label="Moneda"
                  class="required"
                  :item-text="
                    item => item.nombreMoneda + ' (' + item.codigoMoneda + ')'
                  "
                  item-value="id"
                  return-object
                  :rules="[selectRequired('moneda')]"
                  :no-data-text="
                    monedas != null
                      ? 'Selecciona una moneda'
                      : 'No se han encontrado monedas'
                  "
                  menu-props="offset-y"
                  @change="
                    monedaSeleccionada.id === 125
                      ? (tipoCambio = `1`)
                      : (tipoCambio = ``)
                  "
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoMonedaOriginal"
                  :label="
                    `Monto moneda original (${
                      monedaSeleccionada.codigoMoneda
                        ? monedaSeleccionada.codigoMoneda
                        : 'Moneda no seleccionada'
                    })`
                  "
                  :rules="[
                    required('monto moneda original'),
                    notComma('monto moneda original'),
                    decimals10('monto moneda original')
                  ]"
                  maxlength="30"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="tipoCambio"
                  label="Tipo de cambio"
                  :rules="[
                    required('tipo de cambio'),
                    decimals10('tipo de cambio')
                  ]"
                  maxlength="30"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  :disabled="true"
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  suffix="GTQ"
                  v-model="montoTotalQuetzales"
                  label="Monto total quetzales"
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="!confirmacion">
              <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  medium
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  Actualizar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!--fin:: inicio financieros -->
        </v-card-text>
      </v-card>
      <!--fin:: datos financieros -->
    </div>

    <!--inicio:: dialog eliminar pais-->
    <v-dialog
      v-model="dialogEliminarPais"
      persistent
      max-width="600px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Confirmar eliminación de país
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEliminarPaisSecundarioDisabled"
            @click="dialogEliminarPais = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="">
          Está seguro de eliminar el país {{ nombrePais }} del proyecto
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogEliminarPais = false"
            :disabled="btnEliminarPaisSecundarioDisabled"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="eliminarPaisSecundario"
            :loading="btnEliminarPaisSecundarioDisabled"
            :disabled="btnEliminarPaisSecundarioDisabled"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
//import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import { 
  OBTENER_PROYECTO_ACL,
  ACTUALIZAR_DATOS_FINANCIAMIENTO_ACL,
  REGISTRAR_PAIS_SECUNDARIO_ACL,
  ELIMINAR_PAIS_SECUNDARIO_ACL
} from "@/core/services/store/proyectosactoreslocales/proyectoactoreslocales.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

export default {
  name: "SeccionFuenteCooperanteProyectosDescentralizada",
  props: ["id", "tipo", "confirmacion", "tipoUsuario"],
  data() {
    return {
      validForm: false,
      validFormPaisSecundario: false,
      btnPaisSecundarioLoading: false,
      seccionesBloqueadas: false,
      btnRegistroLoading: false,
      cardDatosLoading: false,
      skeletonLoading: false,
      tipoCooperacionSeleccionada: {},
      tiposCooperacion: [],
      procedenciaFondos: [],
      ddProcedenciaFondosLoading: false,
      ddTiposCooperacionLoading: false,
      paises: [],
      ddPaisesLoading: false,
      ddInstitucionesLoading: false,
      institucionesDisbled: false,
      ddInstrumentosCooperacion: false,
      instrumentosCooperacion: [],
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      monedaSeleccionada: {},
      monedas: [],
      ddMonedasLoading: false,
      montoMonedaOriginal: "",
      tipoCambio: "",
      datosItem: {
        proyectosCooperacionDescId: this.id,
        procedenciaFondos: null,
        paisId: null,
        nombreCooperante: "",
        entidadEjecutoraId: null,
        modalidadCooperacionAclId: null,
        montoMonedaOriginal: 0.0,
        tipoCambio: 0.0,
        monedasId: null,
        montoTotalQuetzales: 0,
        nombreEntidadEjecutora: "",
        tipoCooperacionAclId: null,
      },
      datosProyecto: {},
      datosPaisSecundario:{
        proyectosCooperacionDescId: this.id,
        proyectosActoresLocalesId: this.id,
        proyectoId: this.id,
        estadosId: 1,
        paisId: 0,
        usuarioCreacion: "sco"
      },
      montoTotalQuetzales: 0.0,
      ddTiposProyectosScoLoading: false,
      tiposProyecto: [],
      ddModalidadesCooperacionLoading: false,
      modalidadesCooperacion: [],
      paisesSecundarios: [],
      tablePaisesLoading: false,
      nombrePais: "",
      paisId: 0,
      dialogEliminarPais: false,
      btnEliminarPaisSecundarioDisabled: false,
      ...validations
    };
  },

  components: {
    DialogLoader,
    SnackAlert
  },

  methods: {
    resetItems(tipo, proyectoId) {
      if (tipo === 2) {
        this.skeletonLoading = true;
      }
      this.id = proyectoId;
      this.datosItem.proyectosCooperacionDescId = this.id;
      this.obtenerProcedenciaFondos();
      this.obtenerPaises();
      this.obtenerTiposCooperacionAcl();
      this.obtenerTiposProyectosSco();
      this.obtenerModalidadesCooperacionAcl(0);
      this.obtenerInstituciones();
      this.obtenerInstrumentosCooperacionDescentralizada();
      this.obtenerMonedas();

      this.resetForm();
      if (tipo == 2) {
        this.obtenerDatosItem(proyectoId).then(() => {
          this.obtenerPaisesSecundarios(proyectoId);
        });
      }
      this.configurarInstituciones();
      this.configurarProcedenciaFondos();
    },

    resetForm() {
      this.generoSeleccionado = {};
      this.montoTotalQuetzales = 0.0;
      this.datosItem = {
        proyectosCooperacionDescId: this.id,
        procedenciaFondos: null,
        paisId: null,
        nombreCooperante: "",
        entidadEjecutoraId: null,
        tipoCooperacionDescId: null,
        modalidadCooperacionAclId: null,
        montoMonedaOriginal: 0.0,
        tipoCambio: 0.0,
        monedasId: null,
        montoTotalQuetzales: 0,
        nombreEntidadEjecutora: ""
      };
      this.monedaSeleccionada = {};
      this.tipoCambio = "";
      this.montoTotalMonedaOriginal = "";
      this.montoMonedaOriginal = "";
    },

    //Obtener la información del proyecto
    async obtenerDatosItem(proyectoId) {
      this.datosProyecto = {};
      this.skeletonLoading = true;

      await this.$store
        .dispatch(OBTENER_PROYECTO_ACL, { id: proyectoId })
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            this.datosProyecto = res.data;
            if (this.datosProyecto.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }
            this.datosItem.proyectosCooperacionDescId = proyectoId;

            if (this.datosProyecto.paisId)
              this.datosItem.paisId = this.datosProyecto.paisId;

            if (this.datosProyecto.procedenciaFondos)
              this.datosItem.procedenciaFondos = this.datosProyecto.procedenciaFondos;

            if (this.datosProyecto.nombreCooperante)
              this.datosItem.nombreCooperante = this.datosProyecto.nombreCooperante;

            if (this.datosProyecto.tipoCooperacionAclId)
              this.datosItem.tipoCooperacionAclId = this.datosProyecto.tipoCooperacionAclId;

            if (this.datosProyecto.modalidadCooperacionAclId)
              this.datosItem.modalidadCooperacionAclId = this.datosProyecto.modalidadCooperacionAclId;

            if (this.datosProyecto.monedasId) {
              this.obtenerMonedas().then(() => {
                let searchObjectMoneda = this.monedas.find(
                  dato => dato.id == this.datosProyecto.monedasId
                );
                this.monedaSeleccionada = searchObjectMoneda;
                //console.log(searchObjectMoneda)
                this.datosItem.monedasId = this.datosProyecto.monedasId;
              });
            }
            this.tipoCambio = this.datosProyecto.tipoCambio.toString();
            this.montoTotalQuetzales = this.datosProyecto.montoTotalQuetzales.toString();
            this.montoMonedaOriginal = this.datosProyecto.montoMonedaOriginal.toString();
          }
          //this.ddTiposInstrumentoLoading = false;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    obtenerProcedenciaFondos() {
      this.procedenciaFondos = [
        { id: 1, procedencia: "Nacional" },
        { id: 2, procedencia: "Internacional" }
      ];
    },

    //Obtener los tipos de cooperación descentralizada
    async obtenerPaises() {
      this.ddPaisesLoading = true;

      this.paises = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "Pais/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.paises = res.data;
          }
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.paises = [];
          this.ddPaisesLoading = false;
        });
    },

    //Obtener los tipos de cooperación acl
    async obtenerTiposCooperacionAcl() {
      this.ddTiposCooperacionLoading = true;

      this.tiposCooperacion = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TipoCooperacionAcl/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposCooperacion = res.data;
          }
          this.ddTiposCooperacionLoading = false;
        })
        .catch(() => {
          this.tiposCooperacion = [];
          this.ddTiposCooperacionLoading = false;
        });
    },

    //Obtener los tipos de proyecto SCO
    async obtenerTiposProyectosSco() {
      this.ddTiposProyectosScoLoading = true;

      this.tiposProyecto = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TipoProyectoSco/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposProyecto = res.data;
          }
          this.ddTiposProyectosScoLoading = false;
        })
        .catch(() => {
          this.tiposProyecto = [];
          this.ddTiposProyectosScoLoading = false;
        });
    },

    //Obtener las modalidades de cooperación acl
    async obtenerModalidadesCooperacionAcl(tipoProyecto) {
      this.ddModalidadesCooperacionLoading = true;

      this.modalidadesCooperacion = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ModalidadCooperacionAcl/all/${tipoProyecto}/1`
        })
        .then(res => {
          if (res.status === 200) {
            this.modalidadesCooperacion = res.data;
          }
          this.ddModalidadesCooperacionLoading = false;
        })
        .catch(() => {
          this.modalidadesCooperacion = [];
          this.ddModalidadesCooperacionLoading = false;
        });
    },

    /// Obtener instituciones
    async obtenerInstituciones() {
      //this.datosItem.unidadEjecutoraId =0;
      this.ddInstitucionesLoading = true;
      this.instituciones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status == 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          this.ddInstitucionesLoading = false;
          this.instituciones = [];
        });
    },

    async obtenerInstrumentosCooperacionDescentralizada() {
      this.ddInstrumentosCooperacion = true;

      this.instrumentosCooperacion = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "InstrumentosCooperacionDescentralizada/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.instrumentosCooperacion = res.data;
          }
          this.ddInstrumentosCooperacion = false;
        })
        .catch(() => {
          this.instrumentosCooperacion = [];
          this.ddInstrumentosCooperacion = false;
        });
    },

    //Obtener monedas
    async obtenerMonedas() {
      this.monedas = [];

      this.ddMonedasLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Moneda/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.monedas = res.data;
          }
          this.ddMonedasLoading = false;
        })
        .catch(() => {
          this.monedas = [];
          this.ddMonedasLoading = false;
        });
    },

    //Obtener monedas
    async obtenerPaisesSecundarios(proyectoId) {
      this.paisesSecundarios = [];

      this.tablePaisesLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `ProyectosActoresLocales/Financiamiento/PaisSecundario/${proyectoId}` })
        .then(res => {
          if (res.status === 200) {
            this.paisesSecundarios = res.data;
          }
          this.tablePaisesLoading = false;
        })
        .catch(() => {
          this.paisesSecundarios = [];
          this.tablePaisesLoading = false;
        });
    },

    //Configurar las instituciones
    //Si el tipo de cooperacion desc es indirecta 2 o delegada 3, se deben habilitar las instituciones
    configurarInstituciones() {
      if (this.datosItem.tipoCooperacionDescId === 1) {
        this.datosItem.entidadEjecutoraId = null;
        this.datosItem.nombreEntidadEjecutora = "";
        this.institucionesDisbled = true;
      } else {
        this.institucionesDisbled = false;
      }
    },

    configurarProcedenciaFondos() {
      if (this.datosItem.procedenciaFondos === 1) {
        this.datosItem.paisId = null;
      }
    },

    //Actualizar datos de fuente coopreante y datos financieros
    async registrarDatosFinanciamiento() {
      this.datosItem.monedasId = this.monedaSeleccionada.id;
      this.datosItem.tipoCambio = Number(this.tipoCambio);
      this.datosItem.montoTotalQuetzales = Number(this.montoTotalQuetzales);
      this.datosItem.montoMonedaOriginal = Number(this.montoMonedaOriginal);
      if (this.datosItem.tipoCooperacionDescId === 1) {
        this.datosItem.nombreEntidadEjecutora = "";
      }

      this.btnRegistroLoading = true;
      await this.$store
        .dispatch(ACTUALIZAR_DATOS_FINANCIAMIENTO_ACL, {
          datos: this.datosItem
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerBeneficiarios(this.id);
           // this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroLoading = false;
        });
    },

    //Actualizar datos de fuente coopreante y datos financieros
    async registrarPaisSecundario() {
      this.datosPaisSecundario.proyectoId = this.id;
      this.btnPaisSecundarioLoading = true;
      await this.$store
        .dispatch(REGISTRAR_PAIS_SECUNDARIO_ACL, {
          datos: this.datosPaisSecundario
        })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosPaisSecundario = {
              proyectosCooperacionDescId: this.id,
              proyectosActoresLocalesId: this.id,
              proyectoId: this.id,
              estadosId: 1,
              paisId: 0,
              usuarioCreacion: "sco"
            };
            this.obtenerPaisesSecundarios(this.id);
            //this.obtenerBeneficiarios(this.id);
            // this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnPaisSecundarioLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnPaisSecundarioLoading = false;
        });
    },

    mostrarDialogEliminarPais(item) {
      this.nombrePais = item.nombre;
      this.paisId = item.id;
      this.dialogEliminarPais = true;
    },

    async eliminarPaisSecundario() {
      this.btnEliminarPaisSecundarioDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_PAIS_SECUNDARIO_ACL, this.paisId)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerPaisesSecundarios(this.id);
            this.dialogEliminarPais = false;
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarPaisSecundarioDisabled = false;
        })
        .catch(error => {
          this.btnEliminarPaisSecundarioDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      //Deshabilitar tipos de datos financieros para distribución
      //this.tiposDatosFinancierosDistribucion.find( item => item.id === id).disabled = true;
    }
  },

  created() {
    this.resetItems(2, this.id);
  },

  watch: {
    montoMonedaOriginal: function() {
      this.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },

    tipoCambio: function() {
      this.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },
  },

  computed: {
    headersBeneficiarios() {
      return [
        {
          text: "Municipio-Departamento",
          align: "start",
          sortable: false,
          value: "departamento"
        },
        {
          text: "Género",
          align: "start",
          sortable: false,
          value: "nombreGenero"
        },
        {
          text: "Área",
          align: "start",
          sortable: true,
          value: "nombreArea"
        },
        {
          text: "Etnia",
          align: "start",
          sortable: true,
          value: "nombreEtnia"
        },
        {
          text: "Grupo etario",
          align: "start",
          sortable: true,
          value: "rango"
        },
        {
          text: "Beneficiados",
          align: "start",
          sortable: true,
          value: "numeroBeneficiados"
        },

        {
          text: "Monto GTQ",
          align: "start",
          sortable: false,
          value: "monto"
        },
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: ""
        }
      ];
    },

    headersPaises() {
      return [
        {
          text: "Nombre País",
          align: "start",
          sortable: false,
          value: "nombre"
        },
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: ""
        }
      ];
    }
  }
};
</script>
