<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <!--inicio:: condiciones de beneficiarios -->
      <v-row>
        <v-col cols="12" md="6" sm="12" class="pt-1 pb-0">
          <v-subheader class="text-h5 black--text">
            Confirmar información
          </v-subheader>
          <p class="text-subtitle-1 pl-2" style="margin-top: -15px;">
            A continuación se muestran los datos de avance del proyecto
          </p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`"
            >
              Información General
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="
                      informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`
                    "
                    >{{
                      informacionGeneralRegistrada
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="datosFinancierosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Fuente cooperante y financiamiento
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="datosFinancierosRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      datosFinancierosRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="vinculacionEstrategicaRegistrada ? `#26c6da` : `#FFAB91`"
            >
              Vinculación estratégica
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="vinculacionEstrategicaRegistrada ? `#26c6da` : `#FFAB91`"
                    >{{
                      vinculacionEstrategicaRegistrada
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="beneficiariosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Beneficiarios
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="beneficiariosRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      beneficiariosRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="datosInstitucionRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Datos Institución
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="datosInstitucionRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      datosInstitucionRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="
          porcentajeAvance < 100 && datosAvanceProyecto.estadoProyectoId === 1
        "
      >
        <v-col cols="12" md="12">
          <v-alert outlined type="warning" color="#FF7043" border="left">
            Existe información incompleta, por favor complete los campos
            obligatorios para poder confirmar la información del proyecto.
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="datosAvanceProyecto.estadoProyectoId != 1">
        <v-col cols="12" md="12">
          <v-alert outlined type="success" color="#26c6da" border="left">
            La información del proyecto ya se encuentra confirmada!
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <v-btn
            color="light-blue-502"
            class="white--text mb-2"
            type="submit"
            x-large
            :elevation="0"
            :disabled="
              porcentajeAvance < 100 ||
                datosAvanceProyecto.estadoProyectoId != 1
            "
            :loading="btnConfirmarLoading"
            @click="confirmarProyecto"
          >
            Confirmar Información del Proyecto
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_AVANCE_PROYECTO_ACL } from "@/core/services/store/proyectosactoreslocales/proyectoactoreslocales.module";
export default {
  name: "SeccionConfirmarProyectoActoresLocales",
  props: ["id", "tipoAccion", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  components: {
    SnackAlert
  },
  data() {
    return {
      skeletonLoading: false,
      informacionGeneralRegistrada: false,
      datosFinancierosRegistrados: false,
      vinculacionEstrategicaRegistrada: false,
      beneficiariosRegistrados: false,
      datosInstitucionRegistrados: false,
      porcentajeAvance: 0,
      codigoProyecto: "",
      datosAvanceProyecto: {},
      ...validations
    };
  },
  methods: {
    ///Obener el avance de registro del proyecto
    async obtenerAvanceProyectoCns(proyectoId) {
      this.datosAvanceProyecto = {};
      this.skeletonLoading = true;
      await this.$store
        .dispatch(OBTENER_AVANCE_PROYECTO_ACL, { id: proyectoId })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosAvanceProyecto = res.data;
            this.codigoProyecto = this.datosAvanceProyecto.codigo;
            this.verificarDatosSecciones(this.datosAvanceProyecto);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Mensaje",
              res.message
            );
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonLoading = false;
          this.dialogLoaderVisible = false;
          this.datosAvanceProyecto = {};
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    verificarDatosSecciones(datos) {
      datos.informacionGeneralRegistrada == 1
        ? (this.informacionGeneralRegistrada = true)
        : (this.informacionGeneralRegistrada = false);
      datos.datosFinancierosRegistrados == 1
        ? (this.datosFinancierosRegistrados = true)
        : (this.datosFinancierosRegistrados = false);
      datos.vinculacionEstrategicaRegistrada == 1
        ? (this.vinculacionEstrategicaRegistrada = true)
        : (this.vinculacionEstrategicaRegistrada = false);
      datos.beneficiariosRegistrados == 1
        ? (this.beneficiariosRegistrados = true)
        : (this.beneficiariosRegistrados = false);
      datos.datosInstitucionRegistrados == 1
        ? (this.datosInstitucionRegistrados = true)
        : (this.datosInstitucionRegistrados = false);
      datos.datosInstitucionRegistrados == 1
        ? (this.datosInstitucionRegistrados = true)
        : (this.datosInstitucionRegistrados = false);

      this.porcentajeAvance = datos.porcentajeAvance;
    },

    async confirmarProyecto() {
      this.$emit("mostrar-dialog-confirmar", this.id, this.codigoProyecto);
    }
  },
  created() {
    this.obtenerAvanceProyectoCns(this.id);
  }
};
</script>
